<template>
  <div id="agreement">
    <div>
      <div class="title">证企通 用户服务协议</div>
      <div class="lineempty"></div>
      <div class="content">
          <div>协议更新日期: 2021年4月28日</div>
          <div>协议生效日期: 2021年4月30日</div>
      </div>
      <div class="lineempty"></div>
      <div class="title">引言</div>
      <div class="lineempty"></div>
      <div>
        证企通用户服务协议（以下简称“本协议”）是您（或称“用户”）与江苏数字矩阵科技有限公司及其关联公司（以下简称“证企通”）及其运营合作单位之间关于证企通软件、商品及服务所订立的有效合约。
      </div>
      <div class="lineempty"></div>
      <div class="content">
        为了保障您的权益，请在使用本软件及本服务之前，详细阅读本协议所有内容（特别是以黑体字体标示出的关于证企通及用户重大权益的条款）。如果您不同意本协议的任何内容，或者无法准确理解证企通对条款的解释，请不要进行后续操作。当您点击“登陆”或“注册”按钮或其他类似含义的按钮时，即表示您与证企通已达成本协议并同意接受本协议全部约定内容。
      </div>
      <div class="lineempty"></div>
      <div>
        本协议内容包括协议正文、本协议明确援引的其他协议、证企通已经发布的或将来可能发布的各类规则，该等内容均为本协议不可分割的组成部分，与协议正文具有同等法律效力。除另行明确声明外，用户使用证企通软件、购买证企通任何商品及服务均受本协议约束。
      </div>
      <div class="paragraph"></div>
      <div class="title">第一条 定义</div>
      <div class="paragraph"></div>
      <div>
        <span class="title">一、用户：</span>
        <span>
          指接受并同意本协议全部条款及证企通平台发布的其他全部服务条款和操作规则、通过证企通软件和服务进行线上、线下商品选购的注册单位。
        </span>
      </div>
      <div>
        <span class="title">二、证企通平台：</span>
        <span
          >指证企通网站（域名为http://www.jsszjz.cn/）及移动客户端（证企通APP）。</span
        >
      </div>
      <div>
        <span class="title">三、证企通软件：</span
        ><span
          >指由证企通及其关联公司开发并全权授权证企通以自己名义对用户实施许可，供用户下载、安全、使用的软件系统，包括IOS版，安卓版等版本（以下简称为“本软件”）。</span
        >
      </div>
      <div>
        <span class="title">四、证企通商品及服务：</span
        ><span
          >指证企通通过软件系统及证企通开发的其他互联网新技术、新渠道（即“线上服务”），以及通过证企通的线下门店（即“线下服务”）等向用户提供的商品零售及相关配套服务（以下简称为“本服务”）。</span
        >
      </div>
      <div>
        <span class="title">五、合作单位：</span
        ><span
          >为丰富证企通商品的种类并提高我们的服务质量，证企通可能会不时引进证企通及其关联公司以外的其他合作商为消费者提供商品及服务（以下简称为“合作单位”）。</span
        >
      </div>
      <div>
        <span class="content"
          >六、关联公司：指与证企通合作的任何实体单位。</span
        >
      </div>
      <div class="paragraph"></div>
      <div class="title">第二条 本协议的修订</div>
      <div class="paragraph"></div>
      <div>
        <span
          >根据国家法律法规的变化以及为给用户提供更好的服务，证企通有权在必要时通过在证企通平台发出公告等合理方式修改本协议条款。</span
        ><span class="content"
          >用户在使用本软件及本服务时，应当及时查阅了解修改的内容，并自觉遵守本协议的相关内容。用户如继续使用证企通服务，则视为对修改内容的认同，当发生有关争议时，以最新的服务协议为准；用户在不同意修改内容的情况下，有权停止使用本协议涉及的服务。</span
        >
      </div>
      <div class="paragraph"></div>
      <div class="title">第三条 用户注册及账户使用</div>
      <div class="paragraph"></div>
      <div class="title">一、用户注册</div>
      <div class="title">（一）用户资格</div>
      <div>
        您确认，在您开始注册使用本软件及本服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。如果您在18周岁以下，您需要在父母或监护人的监护参与下才能使用本软件及本服务。在您使用本服务时，即视为您已满足上述条件，并对您在使用过程中提供的所有信息的真实性负责。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。
      </div>
      <div class="title">（二）注册</div>
      <div>
        用户须提供合法。真实、准确、完整的个人资料，如有变动，应及时更新用户资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且证企通保留终止用户使用本软件及服务的权利。
      </div>
      <div class="lineempty"></div>
      <div class="title">二、账户密码及安全</div>
      <div>
        （一）用户注册成功后，证企通将给予每个用户一个用户账户，该账号归证企通所有，用户完成注册手续后，获得账号的使用权。注册产生的用户名和密码等账户信息，由用户自行负责保管。如因用户保管不当而对用户自身、证企通或第三方造成损害的，用户将承担全部责任。
      </div>
      <div>
        （二）用户对通过其账户所进行的活动、行为和事件依法享有权利和承担责任；用户不得以赠与、借用、租用、转让、授权或其他任何形式将账户处分给他人。
      </div>
      <div>
        （三）鉴于网络服务的特殊性，证企通没有义务审核是否是用户本人使用该组用户名及密码，仅审核用户名及密码是否与数据库系统中保存的一致，任何人只要输入的用户名及密码与数据库中保存的一致，即可凭借该组用户名及密码登陆并使用证企通所提供的各类服务。因此即使用户认为用户的账户登陆行为并非用户本人所为，证企通不承担因此而产生的任何责任，用户应对自己账户中的所有活动和事件承担全部责任。
      </div>
      <div>
        （四）账户因用户主动泄露或因用户遭受他人诈骗等行为导致
        的损失及后果，证企通不承担任何责任。用户应通过司法、行政等救济途径向侵权行为人追偿。一旦有用户投诉或者证企通有合理怀疑账户使用者并非账户本人时或者存在其他账户安全问题时，证企通有权中止该账户的使用。
      </div>
      <div>
        （五）因黑客行为、计算机病毒侵入或用户保管不当、疏忽致
        使账户、密码被他人非法使用、盗用、篡改或丢失的，请立即通知证企通并向公安机关报案。<span
          class="content"
          >用户理解证企通对用户任何请求采取行动均需要合理时间，且证企通应用户请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除存在法定过错外，证企通均不承担任何责任。</span
        >
      </div>
      <div>
        （六）用户不可以通过自动方式创建账户，也不可以对账户使
        用自动系统执行操作，否则，证企通可自行决定终止、冻结该用户的账户，取消用户参加活动的资格及关闭相关订单。
      </div>
      <div>
        （七）注销用户有权选择继续使用或者注销用户在证企通的账户。用户明确知道，您注销证企通账户的行为将导致证企通终止向您提供产品及服务，账户注销后用户的账户信息、历史交易信息等将被清空且无法恢复，用户获得的红包、优惠券、会员权益等均将被删除，并且视为用户放弃全部历史交易可能产生的资金退回权益。因此请用户在确保账户余额为零，优惠权益均已到期、银行卡已解绑，所有交易已完结且无任何纠纷的情况下，致电我们的客服电话025-85207326，客服将在核实您的身份信息后的3个工作日内为您操作注销您的证企通的账户。
      </div>
      <div class="lineempty"></div>
      <div class="title">三、用户使用规范</div>
      <div>
        (一)您同意并遵守以下相关规则:用户在使用本软件及服务时填写、登录、使用的账户名称、头像、个人简介、用户评价等账户信息资料应遵守法律法规、社会主义制度，不得损害国家利益、公民合法权益、不得违反公共秩序、社会道德风尚和保证信息真实性等七条底线，不得在账户信息资料中出现违法和不良信息，且用户保证在填写、登录、使用账户信息资料时，不得有以下情形:
      </div>
      <div>
        <div>1、违反宪法或法律法规规定的;</div>
        <div>2、危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的;</div>
        <div>3、损害国家荣誉和利益的，损害公共利益的;</div>
        <div>4、煽动民族仇恨、民族歧视，破坏民族团结的;</div>
        <div>5、破坏国家宗教政策，宣扬邪教和封建迷信的;</div>
        <div>6、散布谣言，扰乱社会秩序，破坏社会稳定的;</div>
        <div>7、散布淫秽、色情、赌博、暴力、凶杀、恐怖信息或者教唆犯罪的;</div>
        <div>8、侮辱或者诽谤他人，侵害他人台法权益的;</div>
        <div>9、含有法律、行政法规禁止的其他内容的。</div>
      </div>
      <div>
        (二)若用户注册、登录、使用账户头像、个人简介等账户信息资料存在违法和不良信息的，证企通有权采取通知限期改
        正、暂停使用等措施。对于冒用相关机构或社会名人的名称登录、使用、填写账户名称、头像、个人简介、发布评价的，证企通有权取消、禁止该账户在证企通上继续使用，并向政府主管部门进行报告。
      </div>
      <div>
        (三)用户了解并同意，证企通有权应有关部门的要求，向其
        提供用户提交给证企通或用户在使用本软件及服务中存储于证企通服务器的必要信息。如用户涉嫌侵犯他人台法权益，则证企通有权在初步判断涉嫌侵权行为存在的情况下，向权利人提供关于用户的前述必要信息。除非另有证明，储存在证企通服务器上的数据是用户使用证企通服务的唯一有效证据。
      </div>
      <div class="paragraph"></div>
      <div class="title">第四条 用户信息保护与授权</div>
      <div class="paragraph"></div>
      <div>
        <span class="title">一、证企通非常重视用户个人信息的保护。</span
        >在用户使用本软件及服务时，用户同意证企通按照在证企通平台上公布的<span
          class="content privacyagreement" @click="toSecretAgreement"
          >《隐私政策》</span
        >收集、处理、存储、使用、共享和保护用户的个人信息。证企通希望通过隐私政策向用户清楚地介绍证企通对用户个人信息的处理方式，因此证企通建议用户完整地阅读<span
          class="content privacyagreement" @click="toSecretAgreement"
          >《隐私政策》</span
        >，以帮助用户更好地保护隐私权。
      </div>
      <div class="title">二、特别授权</div>
      <div class="content">
        为方便用户使用本软件及服务，用户授权证企通将用户在账户注册和使用证企通软件及服务过程中提供、形成的信息传递给证企通及其关联公司、合作单位，或从证企通关联公司其他合作单位获取用户在注册、使用相关服务期间提供、形成的信息。
      </div>
      <div class="title">三、通知</div>
      <div>
        所有发给用户的通知都可通过电子邮件、短信、网页公告、软件内公告、软件内弹窗或在证企通平台显著位置公告或常规的信件等方式进行送达。该等通知于发送之日视为已送达用户。
      </div>
      <div class="content">
        为使用户及时、全面了解证企通的各项服务，用户同意证企通可以多次、长期向用户发送各类商业性短信息而无需另行获得用户的同意。
      </div>
      <div class="paragraph"></div>
      <div class="title">第五条 证企通软件服务</div>
      <div class="paragraph"></div>
      <div class="title">一、使用本软件及服务，您必须:</div>
      <div>
        (一)自行配备上网所需设备，包括个人手机、电脑、调制解 调器、路由器等;
      </div>
      <div>
        (二)自行负担个人上网所支付的与本服务有关的电话费用、 网络费用等;
      </div>
      <div>
        (三)选择与所安装终端设备相匹配的软件版本，包括但不限
        于iOS、Android等多个证企通发布的应用版本。
      </div>
      <div class="title">二、软件的获取与更新</div>
      <div>
        (一)您可以直接从证企通的官方网站上扫描二维码下载、获
        取本软件，也可以从得到证企通授权的第三方下载平台获取。
        如果您从未经证企通授权的第三方获取本软件或与本软件名称相同的安装程序，证企通无法保证该软件能够正常使用，并对因此给您造成的损失不承担任何责任。
      </div>
      <div>
        (二)由于软件适配平台及终端限制，您仅能在许可软件列明
        的系统终端，且在中华人民共和国大陆地区使用本软件;您将
        本软件安装在非对应系统的其它终端设备上的，可能会对您硬件或软件功能造成损害。
      </div>
      <div class="title">三、软件使用范围</div>
      <div>
        (一)本软件仅用于非商业目的及您的个人消费合理自用，您
        不可为商业运营目的安装、使用、运行本软件。
      </div>
      <div>
        (二)为了改善用户体验、完善服务内容保证本软件与证企通
        提供服务的商业诉求一致，证企通会对本软件及其相关服务功能界面等进行更新和修改，包括但不限于开发新功能，删除旧功能等。新版本发布后，旧版本的软件可能无法使用，证企通不保证旧版本软件继续可用及相应的客户服务，请您随时核对并下载最新版本。
      </div>
      <div class="title">四、软件使用规范</div>
      <div>(一)您应该规范使用本软件，以下方式是违反使用规范的:</div>
      <div>
        1、从事危害网络信息安全、欺诈、盗用账户或个人信息或资金等违法犯罪活动;发表、传播、储存、侵害证企通及他人知识产权商业秘密或其他违反国家法律法规政策破坏公序良俗损害公共利益的行为;删除本软件及其副本上关于著作权的信息;
      </div>
      <div>
        2、对本软件进行反向工程、反向汇编、反向编译或者以其他方式尝试发现本软件的源代码;
      </div>
      <div>
        3、对证企通拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等;
      </div>
      <div>
        4、对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经证企通授权的第三方工具或服务接入本软件和相关系统;
      </div>
      <div>
        5、通过修改或伪造软件运行中的指令、数据，增加、删减、
        变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的;
      </div>
      <div>
        6、通过非证企通开发、授权的第三方软件、插件、外挂、系统，登录或使用本软件及服务，或制作、发布、传播上述工具;
      </div>
      <div>
        7、自行或者授权他人、第三方软件对本软件及其组件、模 块、数据进行干扰;
      </div>
      <div>
        8、其他以任何不合法的方式，不合法的目的或以任何与本协议约定不一致的方式使用本软件。
      </div>
      <div class="content">
        (二)您理解井同意如您涉嫌违反上述使用规范，证企通可能
        会采取以下一种或多种措施:
      </div>
      <div class="content">
        <div>1、中止或终止对您提供本软件许可与本服务;</div>
        <div>
          2、删除涉嫌违法、违规、违反政策、侵犯他人合法权利(包
          括但不限于知识产权、肖像权、名誉权等)或违反本协议约定 的信息;
        </div>
        <div>3、其他依照协议约定或相关规则规定可采取的限制措施。</div>
      </div>
      <div class="paragraph"></div>
      <div class="title">第六条 证企通商品及服务规范</div>
      <div class="paragraph"></div>
      <div>
        一、证企通向您提供包括商品信息展示和搜索、选购及订单、
        配送等服务，您知晓并同意随着本服务的运营与发展，我们将不断的更新优化服务内容，您同意使用所有证企通现有及未来的服务均需要遵循本协议中的各项条款与规则。
      </div>
      <div>
        二、由于服务能力有限，证企通和合作单位只服务于为企业发展需要而使用本服务的用户。用户承诺非经证企通书面同意，用户不能利用本服务进行采购、销售或其他商业用途。以营利为目的而使用本服务的订单有悖于证企通服务善意消费者的理念，您同意证企通可以基于重大误解撤销已与您实际履行的订单。
      </div>
      <div>
        三、您理解并同意:展示的商品和价格等信息仅仅是要约邀
        请，您下单时须填写您希望购买的商品数量、价款及支付方
        式、收货人、联系方式、收货地址、合同履行方式等内容。系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，仅是您向证企通发出的合同要约，证企通或合作单位收到您的要约信息后有权结合商品库存交易的真实性与合理性等因素，合理判断是否与您就本订单商品确认合同关系。如发现您或者您的要约涉嫌不真实，不诚信或其他原因等违反本协议约定等情形的，证企通或合作单位不会接受您的要约;如此时您已付款的，您会收到退款。
      </div>
      <div>
        四、在您提交订单时，请您仔细确认所购商品的名称、价格、
        数量、型号、规格、尺寸、联系地址、电话、收货人等信息。
        收货人与您本人不一致的，收货人的行为和意思表示视为您的行为和意思表示，您应对收货人的行为及意思表示的法律后果承担连芾责任。
      </div>
      <div>
        五、您理解并同意，证企通采用先付款后服务的方式，用户应在确认订单时，选择付款方式，并严格按照已选择的方式付款。用户未能按照所选择的方式或在指定时间完成付款的，证企通有权关闭订单。
      </div>
      <div>
        六、证企通和合作单位都可能会基于市场变化、商品库存、保护不特定用户权益及各种以合理商业努力难以控制因素等影响对商品订购数量作出限制。您在发出要约时应注意真实、合理地消费。如您拟购买的商品发生缺货或超过订购限制数量的，证企通或合作单位和您都有权取消订单，若您已经付款，则为您办理退款。
      </div>
      <div>
        七、您理解并同意，为最大限度的提高商品信息的准确性、及时性、完整性和有效性，证企通有权利对商品信息进行及时的监测、修改或删除。如用户提交订单后，证企通发现相关页面上包括但不限于商品名称、价格或数量价格等关键信息存在标注错误的，应及时通知用户，并有权关闭错误订单。
      </div>
      <div>
        八、用户应当保证在使用本服务进行交易过程中遵守诚实信用的原则。请务必依据系统程序填写并提交订单。该存储于证企通服务器的订单数据将被认为是用户的该次交易对应的发货、退货和争议事项的依据。用户“提交订单”意味着用户认可订单中包含的所有信息都是正确、合法和完整的，用户须对其在使用本服务过程中的上述行为承担法律责任。
      </div>
      <div>九、您同意并遵守以下配送服务相关规则:</div>
      <div>
        (一)为保证商品服务及时准确、服务品质令人满意，您应准
        确填写服务需求，项目要求等服务信息，并授权证企通将您的信息交予我们的项目经理处，便于及时准确的联系您。
      </div>
      <div>
        (二)为便于您及时了解订单信息、项目服务信息等与订单进展等密切相关的信息，提升您使用本服务的体验，您理解证企通在遵守《隐私政策》的前提下，以邮件、短信、信息推送、电话等方式向您提供相关商品信息。
      </div>
      <div>
        (三)您同意并保证:为了更好的为您提供服务，证企通有权
        记录用户在选购商品过程中在线填写的所有信息，并提供给相应的合作单位(包括但不限于合作方、服务方等)。用户保证该等信息准确、合法，该等信息将作为用户本次交易的不可撤销的承诺，用户承担因该等信息错误、非法等导致的后果。如用户提供的信息过期、无效进而导致无法与用户取得联系的，因此导致用户在使用本服务中产生任何损失或增加费用的，应由用户完全独自承担。
      </div>
      <div>
        (四)预计服务完成时间系基于企业配合程度等因素的理想状态由计算机系统估算生成，这并非证企通就完成时间对您做出的精准承诺;希望您理解由于受政府政策、企业配合等问题有合理可能早于或晚于实际完成时间的。
      </div>
      <div>
        (五)因如下情况造成订单延迟或无法配送等，您不应当要求
        证企通承担赔偿或补偿的责任:
      </div>
      <div>1、因您提供的信息有误、不详细等原因导致的;</div>
      <div>2、情势变更因素导致的;</div>
      <div>3、因节假日、政府政策等原因导致的;</div>
      <div>4、不可抗力因素导致的，例如:自然灾害、交通戒严、突发 战争等。</div>

      <div class="paragraph"></div>
      <div class="title">第七条 价格说明</div>
      <div class="paragraph"></div>

      <div>
        一、销售价:证企通为商品设置的销售价，是您最终决定是否 购买商品的依据。
      </div>
      <div>
        二、折扣:如无特殊说明，折扣指在销售价等某- -价
        格基础_上计算出的优惠比例或优惠金额;如有疑问，您可以购买前联系证企通客服进行咨询。
      </div>
      <div>
        三、价格异常问题:商品促销信息以商品详情页促销说明或活
        动规则中的信息为准;商品的具体售价以订单结算页为准;如
        您发现促销商品售价或促销信息有异常，建议购买前先联系证企通客服。
      </div>

      <div class="paragraph"></div>
      <div class="title">第八条 各类活动补充规则及说明</div>
      <div class="paragraph"></div>

      <div>
        一、用户账户内的任何优惠、促销权益仅供用户本人在证企通购物时的使用，用户不得转卖、转让予他人。
      </div>
      <div>
        二、一般情况下活动优惠是不能同时享受的，能否以各类优惠活动叠加的形式购买特定商品与服务请以具体活动页面规则内容为准。
      </div>
      <div>
        三、某些商品不能使用您参加活动领取或者兑换的立减券、优惠券、免单券等优惠券，能否以优惠价格购买具体商品以最终订单页面信息为准。
      </div>
      <div>四、任何优惠券均需在有效期内使用，过期则不能再使用。</div>
      <div>
        五、任何优惠券均不能够兑换成现金，您只能凭券进行消费，
        如遇退单情况，退款仅按照实际支付金额结算。
      </div>
      <div>
        六、部分抽奖类活动，您应当及时关注活动页面及获奖信息、
        领奖时间、地点等。如领奖需证企通工作人员主动联系您的,
        将根据具体活动规则在活动结束后一定时间内与您联系，
        确定奖品发放事宜，您需填写准确、详细的个人联系方式，若届时无法与您取得联系，视为您自动放弃获奖资格。
      </div>
      <div>
        七、部分活动规则限制同一用户在同一活动中的参与次数，您须按照具体活动规则参与活动。同一用户，即证企通App账号、绑定手机号、支付手机号、支付账号、使用设备等上述任何一项相同均视为同一用户。
      </div>
      <div>
        八、活动过程中，任何活动参与方存在虚假交易、恶意套利、
        作弊等不诚信行为，证企通有权取消活动参与方的参与资格、废止优惠券等优惠权益，并保留依法追完法律责任的权利。
      </div>
      <div>
        九、如用户对活动内容及具体规则有任何疑问，用户均可以咨询证企通客服，客服热线:
        025-85207326。
      </div>

      <div class="paragraph"></div>
      <div class="title">第九条 协议终止和违约责任</div>
      <div class="paragraph"></div>

      <div class="title">一、协议的中止、中断、终止:</div>
      <div>
        证企通根据自身商业决策等原因可能会选择中止、中断及终
        止本服务。如有此等情形发生，证企通会采取公告的形式通知用户。经国家行政或司法机关的生效法律文书确认用户存在违法或侵权行为，或者证企通根据自身的判断，认为用户的行为涉嫌违反本协议或涉嫌违反法律法规的规定的，则证企通有权中止、中断或终止向用户提供服务，且无须为此向用户或任何第三方承担责任。
      </div>
      <div class="title">二、违约责任:</div>
      <div>
        (一)如果证企通发现或收到他人举报、投诉您违反本协议约
        定的，证企通有权不经通知随时对相关内容进行删除、屏蔽，
        并视行为情节对违规账户处以包括但不限于警告、限制或禁止使用部分或全部功能、账户封禁直至注销的措施，并公告处理结果。
      </div>
      <div>
        (二)证企通有权依据合理判断对违反有关法律法规或本协议
        规定的行为采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一-切法律责任。
      </div>
      <div>
        (三)您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何素赔、要求或损失，您应当独立承担责任;证企通因此遭受损失的，您也应当承担赔偿责任。
      </div>
      <div class="title">三、无担保和责任限制</div>
      <div>
        (一)除非法律规定或者便公示的声明与承诺，证企通不对本软件及服务的运营及其包含在本软件上的信息、内容、材料或服务作任何形式的、明示或默示的声明或担保;证企通亦不对所提供的商品的适用性或满足用户特定需求及目的进行任何担保。请在使用和购买前确认自身的需求，同时仔细阅读商品的具体说明。
      </div>
      <div>
        (二)您理解证企通将尽最大努力确保本软件及证企通服务其所涉及的技术及信息安全、有效、准确、可靠，但受限于现有技术及证企通有限的服务能力，对于下述原因导致的台同履行障碍瑕疵、延后或内容变更等情形，导致您直接或间接损失，您理解便利蜂不承担任何责任:
      </div>
      <div>
        1、因自然灾害罢工、暴乱、战争、恐怖袭击、政府行为、司法行政命令等不可抗力因素;
      </div>
      <div>2、因电信部门技术调整或故障通讯网络故障等公共服务因 素;</div>
      <div>
        3、用户自身设备的软件、系统、硬件和通信线路出现故障，
        或用户通过非本协议项下约定的方式使用本软件服务的;
      </div>
      <div>
        4、证企通已善意管理，但出现紧急设备、系统的故障、维护网络信息与数据安全需要等情况。
      </div>
      <div>
        5、如因上述不可抗力或其他证企通无法控制的原因使证企通的系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，证企通公司不承担责任。但是证企通会尽可能合理地协助处理善后事宜，并努力使用户免受经济损失。
      </div>
      <div class="content">
        (三)基于互联网的特殊性，证企通平台显示的信息可能有一
        定的滞后性或差异，对此情形用户知悉并理解;用户须自行辨
        别真伪，谨慎预防风险。
      </div>
      <div class="content">
        (四)基于收益与赔偿相一致及公平合理的原则，如因证企通原因造成本软件及服务不正常中断或不可用或其他原因造成用户损失的，用户所可能获得的最高赔偿额不超过本协议项下证企通己实际支付的费用的总额。
      </div>

      <div class="paragraph"></div>
      <div class="title">第十条 所有权及知识产权</div>
      <div class="paragraph"></div>

      <div>
        一、证企通平台上所有内容，包括但不限于文字、软件、声
        音、图片、录像、图表、网站架构、网站画面的安排、网页设计、在广告中的全部内容、商品以及其它信息均由证企通或其他权利人依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。非经证企通或其他权利人书面同意，用户不得擅自使用、修改、全部或部分复制、公开传播、改变、散布、发行或公开发表、转载、引用、链接、抓取或以其他方式使用本平台程序或内容。如有违反，用户同意承担由此给证企通或其他权利人造成的一切损失。
      </div>
      <div>
        二、证企通尊重知识产权并注重保护用户享有的各项权利。在证企通，用户可能需要通过发表评论等各种方式向证企通提供内容，用户在证企通平台发表的使用体验、讨论或图片等所有信息，除署名权、发表权、修改权外的其他知识产权权利(包括但不限于:复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利)，均无偿授权许可给证企通排他使用，并同意证企通以自己名义就所述权利的保护采取任何形式的法律行为，并获得全部赔偿。
        <span class="content"
          >本协议已经构成《著作权法》第二十五条所规定的书面协议，其效力及于用户在证企通平台发布的任何受著作权法保护的作品内容，无论该内容形成于本协议签订前还是本协议签订后。</span
        >
      </div>

      <div class="paragraph"></div>
      <div class="title">第十一条 法律管辖及适用</div>
      <div class="paragraph"></div>
      <div>
        一、本协议的订立、执行和解释及争议的解决均应适用中华人民共和国大陆地区法律。当本协议的任何内容与上述法律相抵触时，应当以法律规定为准，同时相关协议将按法律规定进行修改或重新解释，而本协议其他部分的法律效力不变。
      </div>
      <div>
        二、如用户在使用证企通服务过程中出现纠纷，应进行友好协商，若协商不成，任何一方均可向南京市江北新区人民法院提起诉讼。
      </div>

      <div class="paragraph"></div>
      <div class="title">第十二条 其他</div>
      <div class="paragraph"></div>
      <div>
        一、本协议将对您持续有效，有效期至您注销或被取消账户、
        停止使用本软件及服务的行为时，但证企通与您之间已经产生的权利义务，仍对双方有约束力。
      </div>
      <div>
        二、
        证企通将视向您所提供服务内容之特性，要求您在接受证企通提供的有关服务时，遵守特定的条件和条款，如该特定条件和条款与本协议内容有任何不一致之处，则以该特定条件和条款为准。
      </div>
      <div>
        三、本协议的标题、序号均为方便阅读而设定，不影响条款实际含义及其效力。本协议内容中以加粗方式显著标识的条款，请您着重阅读。
      </div>
      <div>
        四、证企通公司不行使、未能及时行使或者未充分行使本协议或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响证企通在将来行使该权利。
      </div>
      <div>
        五、用户对于证企通的通知应当通过证企通对外正式公布的通信地址、传真号码、电子邮件地址等联系信息进行送达。该等通知以证企通实际收到日为送达日。
      </div>
      <div>
        本协议有效期持续至更新版本发布之时。如果用户对本协议内容有任何疑问，请发送邮件到
        bd@jsszjz.cn
      </div>
    </div>
  </div>
</template>
 
 <script>
export default {
  name: "Agreement",
  data() {
    return {};
  },
  methods:{
    	toSecretAgreement(){
			  let routeData1 = this.$router.resolve({ name: 'agreement1'})
			  window.open(routeData1.href,'_blank')
			},
  }
};
</script>
 
<style lang="scss" >
#agreement {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  font-size: 16px;
  z-index: 10000;
  background-color: #fff;
  padding: 10px;

  .title {
    font-weight: 700;
  }
  .lineempty {
    height: 20px;
  }
  .content {
    font-weight: 700;
  }
  .paragraph {
    height: 30px;
  }
  .privacyagreement{
    font-size: 700;
    text-decoration: underline;
    &:hover{
      cursor: pointer;
    }
  }
}
</style>